import React from "react"
import Layout from "src/components/layout"
import PortfolioHeader from "src/components/portfolioHeader"

const Photography = () => (
  <Layout>
    <PortfolioHeader />
    <h1>Photography</h1>
  </Layout>
)

export default Photography
