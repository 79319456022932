import { Link } from "gatsby"
import React from "react"
import styles from "src/components/portfolioHeader.module.css"

const ListLink = props => (
  <li className={styles.nav}>
    <Link to={props.to} className={props.className} activeClassName={styles.active} partiallyActive={true}>{props.children}</Link>
  </li>
)

const PortfolioHeader = (props) => (

  <div className={styles.header}>
    <ul>
      <ListLink to="/portfolio/coding" className={props.highlightFirst == true ? styles.active : null}>Coding</ListLink>
      <ListLink to="/portfolio/design">Design</ListLink>
      <ListLink to="/portfolio/photography">Photography</ListLink>
    </ul>
  </div>
)

PortfolioHeader.defaultProps = {
  highlightFirst: false,
}

export default PortfolioHeader
